body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-family: "Rubik";
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

input[type="checkbox"] {
  visibility: hidden;
}
label {
  cursor: pointer;
}
input[type="checkbox"] + label:before {
  border: 2px solid #d5d5d5;
  content: "\00a0";
  display: inline-block;
  font: 14px/1em rubik;
  height: 15px;
  margin: .25em .5em .25em -0.5em;
  padding: 0;
  vertical-align: top;
  width: 15px;
}
input[type="checkbox"]:checked + label:before {
  background: #ACDAFC;
  color: #fff;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
}

input[type="radio"] {
  visibility: hidden;
}

input[type='radio']:after {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #d5d5d5;
}

input[type='radio']:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  position: relative;
  background-color: #ACDAFC;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #d5d5d5;
}


/* 
@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
} */

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./Assets/font/Rubik-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
#scrollBar::-webkit-scrollbar {
  width: 10px;
}
#scrollBar {
  scrollbar-width: thin;
  scrollbar-color:#d5d5d5;
  background-color: #F5F5F5;
}

#scrollBar::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: rgb(241,242,245);
  width:10px
}
#scrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D5d5d5;
  border-right: 2px rgb(241,242,245) solid;
  border-left: 2px rgb(241,242,245) solid;
}

/* .slide-enter {
  transform: translateY(-100%);
  transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-leave {
  transform: translateY(0%);
  transition: .3s ease-in-out;
}

.slide-leave.slide-leave-active {
  transform: translateY(-100%);
} */

.slide,
.slide.ui {
  display: block;
  margin-top: 1em;
}

.slide-enter.slide-enter-active {
  transition: opacity 300ms ease-in, margin 300ms ease-out,
    transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.slide-leave.slide-leave-active {
  transition: opacity 300ms ease-out, margin 300ms ease-in;
}

.slide-enter,
.slide-leave.slide-leave-active {
  opacity: 0;
}

.slide-enter.slide-enter-active,
.slide-leave {
  opacity: 1;
}
